<template>
  <div id="app">
    <div v-if="logged" >
      <Search v-show="searching == true" @selected="selectVeicle" />
      <Veicle v-if="searching == false" @back="searching = true" :selected="selectedVeicle" /> 
    </div>
    <Login @login="authenticated" v-else />
  </div>
</template>

<script>
import Login from './components/Login.vue'
import Search from './components/Search.vue'
import Veicle from './components/Veicle.vue'

export default {
  name: 'app',
  components: {
    Login,
    Search,
    Veicle
  },
  data: function() {
    return {
      logged: false, //Change it to false;
      searching: true,
      selectedVeicle: null
    }
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('token') && urlParams.get('token') === 'scar_945') {
      this.authenticated();
    }
  },
  methods: {
    authenticated() {
      this.logged = true;
      this.$crisp.load('72ff0c2c-3d16-4b55-8712-ca089df48a21')
    },
    selectVeicle(payload) {
      this.selectedVeicle = payload;
      this.searching = false;
    }
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Teko&display=swap&subset=latin-ext');
  body {
    font-family: 'Teko', sans-serif;
    font-size: 1.15rem;
    background-image: url("/assets/bg.png");
    background-repeat: repeat;
  }
  .h5, h5 {
    font-size: 1.45rem;
  }

  .item-rounded {
    border-radius: 1rem;
  }

  div.logo-container {
    margin-top: 50px;

    img.logo {
        width: 250px;
    }
  }

  .item-semi-rounded {
      border-radius: 5px;
  }

  tr th {
      color: #c3c3c3;
      font-weight: 400
  }

  .btn-scar {
    background-color: #d40074;
    color:white;

    &:hover {
      color: white;
    }

    &:focus {
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(212,0,116,.25);
      box-shadow: 0 0 0 0.2rem rgba(212,0,116,.25);
    }
  }
</style>

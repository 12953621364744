<template>
<div>
    <div class="container">
        <div class="text-center logo-container">
            <img class="logo" alt="Scar logo" src="/assets/logo.svg">
        </div>

        <div>
            <!-- Blocco 1 - Tipologia -->
            <div class="mt-5">
                <div class="card card-box">
                    <div class="card-body">
                        <h5 class="box-title">
                            <div class="circle-text d-inline-block mr-2">1</div>
                            <span>Seleziona la tipologia del veicolo</span>
                        </h5>
                        <div class="row mt-3">
                            <div v-for="(type, index) in availableTypes" :key="index" class="col-6 col-md-4 col-lg-3 col-xl-2 mb-3" @click="chooseType(type)">
                                <div class="card card-item card-veicles" :class="[type.slug == choosenType ? 'selected' : '']">
                                    <div class="card-body text-center">
                                        <div class="card-img-top icon" :style="'background-image: url(' + type.icon + ')'"></div>
                                        <span class="card-text text-uppercase" v-text="type.name"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Blocco 2 - Marca-->
            <div class="mt-5" v-if="choosenType != '' && availableBrands.length > 0">
                <div class="card card-box">
                    <div class="card-body">
                        <h5 class="box-title">
                            <div class="circle-text d-inline-block mr-2">2</div>
                            <span>Seleziona la marca del veicolo</span>
                        </h5>
                        <div class="row mt-3">
                            <div v-for="(brand, index) in availableBrands" :key="index" class="col-6 col-md-4 col-lg-3 mb-3" @click="chooseBrand(brand)">
                                <div class="card card-item p-4" :class="[brand == choosenBrand ? 'selected' : '']">
                                    <img style="height: 100px" class="card-img-top" :src="'/assets/brands/' + brand.toLowerCase() + '.svg'" onerror="this.src='/assets/car-placeholder.png';">
                                    <!--
                                    <div class="card-body text-center">
                                        <span class="card-text text-uppercase" v-text="brand"></span>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Blocco 3 - Modello -->
            <div class="mt-5" v-if="choosenBrand != '' && availableModels != null">
                <div class="card card-box">
                    <div class="card-body">
                        <h5 class="box-title">
                            <div class="circle-text d-inline-block mr-2">3</div>
                            <span>Seleziona il modello del veicolo</span>
                        </h5>
                        <div class="row mt-3">
                            <div v-for="(model, index) in availableModels" :key="index" class="col-6 col-md-4 col-lg-3 mb-3" @click="chooseModel(model.model)">
                                <div class="card card-item" :class="[model.model == choosenModel ? 'selected' : '']" style="height: 240px;">
                                    <img style="height: 200px" class="card-img-top" :src="'/assets/veicoli/' + choosenBrand + '/' + choosenType.toLowerCase() + '/' + model.model.replace(/ /g, '_').replace(/\W/g, '').toLowerCase() + '/veicolo.jpg'" onerror="this.onerror=null;this.src='/assets/car-placeholder.png'" data-missing="">
                                    <div class="text-center" style="line-height: 40px;">
                                        <span class="card-text text-uppercase" v-text="model.model.replace(/_/g, ' ')"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding: 4rem 0 4rem 0"></div>
        </div>
    </div>
    <div class="container-fluid footer p-0" v-show="canNextStep">
        <!-- TASTO AVANTI-->
        <div class="container">
            <div class="row justify-content-between align-items-center text-uppercase">
                <div class="col-auto">
                    <span v-text="choosenTypeLabel"></span>
                    <span class="mx-3" style="color: #ccc">/</span>
                    <span v-text="choosenBrandLabel"></span>
                    <span class="mx-3" style="color: #ccc">/</span>
                    <span v-text="choosenModelLabel"></span>
                </div>
                <div class="col-auto">
                    <button class="btn btn-block btn-scar item-rounded text-uppercase" @click="askedForNextStep">prosegui</button>
                </div>
            </div>
 
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'Search',
    data: function() {
        return {
            debug: false,
            availableTypes: [
                { name: 'Furgoni', slug: 'furgoni', icon: '/assets/icons/furgoni.svg' },
                { name: '5 posti', slug: 'vettura_doppia_cabina', icon: '/assets/icons/5-posti.svg' },
                { name: '6/7 posti', slug: 'veicoli_6_7posti', icon: '/assets/icons/6-7-posti.svg' },
                { name: '8/9 posti', slug: 'veicoli_trasporto_persone', icon: '/assets/icons/8-9-posti.svg' },
                { name: 'Camion', slug: 'camion', icon: '/assets/icons/camion.svg' },
                { name: 'Sedili speciali', slug: 'sedili_speciali', icon: '/assets/icons/sedili_speciali.svg' },
                { name: 'Elite', slug: 'elite', icon: '/assets/icons/elite.svg' },
            ],
            choosenType: '',
            choosenTypeLabel: '',
            availableBrands: [],
            choosenBrand: '',
            choosenBrandLabel: '',
            availableModels: [],
            choosenModel: '',
            choosenModelLabel: ''
        }
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        chooseType(type) {
            this.choosenType = type.slug;
            this.choosenTypeLabel = type.name;
            
            this.resetBrand();
            this.resetModel();

            this.$axios.post('/ajax.php', {
                action: 'getBrands',
                type: this.choosenType
            })
            .then(response => {
                if(response.data.code == 200) {
                    this.availableBrands = response.data.brands;
                }
            });
        },
        chooseBrand(brand) {
            this.choosenBrand = brand;
            this.choosenBrandLabel = brand;

            this.resetModel();

            this.$axios.post('/ajax.php', {
                action: 'getModels',
                type: this.choosenType,
                brand: this.choosenBrand,
            })
            .then(response => {
                if(response.data.code == 200) {
                    this.availableModels = response.data.models;
                }
            });
        },
        chooseModel(model) {
            this.choosenModel = model;
            this.choosenModelLabel = model.replace(/_/g, ' ');
        },
        resetBrand()
        {
            this.availableBrands = [];
            this.choosenBrand = '';
            this.choosenBrandLabel = '';
        },
        resetModel()
        {
            this.availableModels = [];
            this.choosenModel = '';
            this.choosenModelLabel = '';
        },
        askedForNextStep()
        {
            this.$emit('selected', {
                type: this.choosenType,
                brand: this.choosenBrand,
                model: this.choosenModel
            });
        }
    },
    computed: {
        canNextStep() {
            return this.choosenTypeLabel && this.choosenBrandLabel && this.choosenModelLabel;
        }
    }
}
</script>

<style lang="scss">

    div.card.card-box {
        border: 1px #d40074 solid;
        
        .card-item {
            cursor: pointer;
            border: 2px #f7f7f7 solid;
            background-color: #f7f7f7;
            
            filter: grayscale(100%);
            opacity: 0.5;

            &.selected, &:hover {
                border: 2px #d40075 solid;
                color: #d40075;
                background-color: rgba(212,0,116,.25);
                opacity: 1;
                filter: none;
            }
            
            &.card-veicles {
                .icon {
                    //background-image: url(/assets/icons/veicles_selected.svg);
                    height: 60px;
                    width: 60px;
                    background-repeat: no-repeat;
                    margin: auto;
                }

                /*&.selected {
                    .icon {
                        background-image: url(/assets/icons/veicles_selected.svg);
                    }
                }*/
            }
        }
    }

    .circle-text {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        background: #d40074
    }

    .footer {
        background-color: white;
        height: 100px;
        line-height: 100px;
        width: 100%;
        position: fixed;
        bottom: 0;

        @media (max-width: 420px) {
            display: flex;
            flex-direction: column;
            line-height: 1.8;
            align-items: center;
            justify-content: center;

            div{
              text-align: center;
              width: 100%;
              margin: 0 auto;
            }

        }

    }

    .card-img-top {
        object-fit: contain;
    }
</style>
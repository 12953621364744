<template>
    <div class="container">
        <div class="text-center logo-container">
            <img class="logo" alt="Scar logo" src="/assets/logo.svg" />
        </div>
        <div class="row my-4">
            <div class="col">
                <span class="backButton" @click="$emit('back')"><img src="/assets/icons/arrow_left.svg" /> <u class="ml-3">Torna al configuratore</u></span>
            </div>
        </div>
        <div class="card">
            <div class="card-body" v-if="choosenVeicle">
                <div class="row justify-content-center box-head py-2 mt-3">
                    <div class="col text-right pr-4">
                        <span class="key">Marca:</span> <span class="text-uppercase">{{ choosenVeicle.brand }}</span>
                    </div>
                    <div class="col-auto middle px-4">
                        <span class="key">Modello:</span> <span class="text-uppercase">{{ choosenVeicle.model.replace(/_/g, " ").toUpperCase() }}</span>
                    </div>
                    <div class="col text-left pl-4">
                        <span class="key">Varianti:</span> <span class="text-uppercase">{{ veicles.length }}</span>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-12 col-md-4">
                        <img class="card-img-top" :src="baseUrl + '/veicolo.jpg'" onerror="this.src='/assets/car-placeholder.png';" />
                    </div>
                    <div class="col-12 col-md-8 my-4">
                        <div class="row">
                            <div v-if="choosenVeicle.color_scar_grey != null && choosenVeicle.color_scar_grey.length > 0" class="col text-center">
                                <img
                                    class="img-fluid item-semi-rounded color-image"
                                    :style="[choosenVeicle.color_original != null && choosenVeicle.color_original.length > 0 ? 'height: 150px' : 'height: 190px']"
                                    :src="baseUrl + '/scar/scar_grigio.jpg'"
                                />
                                <span class="d-block text-uppercase mt-2">Grigio</span>
                            </div>
                            <div v-if="choosenVeicle.color_scar_black != null && choosenVeicle.color_scar_black.length > 0" class="col text-center">
                                <img
                                    class="img-fluid item-semi-rounded color-image"
                                    :style="[choosenVeicle.color_original != null && choosenVeicle.color_original.length > 0 ? 'height: 150px' : 'height: 190px']"
                                    :src="baseUrl + '/scar/scar_nero.jpg'"
                                />
                                <span class="d-block text-uppercase mt-2">Nero</span>
                            </div>
                            <div v-if="choosenVeicle.color_scar_blue != null && choosenVeicle.color_scar_blue.length > 0" class="col text-center">
                                <img
                                    class="img-fluid item-semi-rounded color-image"
                                    :style="[choosenVeicle.color_original != null && choosenVeicle.color_original.length > 0 ? 'height: 150px' : 'height: 190px']"
                                    :src="baseUrl + '/scar/scar_blu.jpg'"
                                />
                                <span class="d-block text-uppercase mt-2">Blu</span>
                            </div>
                            <div v-if="choosenVeicle.color_original != null && choosenVeicle.color_original.length > 0" class="col text-center">
                                <img class="img-fluid item-semi-rounded color-image" style="height: 150px;" :src="baseUrl + '/originale.jpg'" />
                                <span class="d-block text-uppercase mt-2">Originale</span>
                            </div>
                            <div v-if="choosenVeicle.color_elite_black != null && choosenVeicle.color_elite_black.length > 0" class="col text-center">
                                <img class="img-fluid item-semi-rounded color-image" style="height: 150px;" :src="baseUrl + '/color_elite/elite_nero.jpg'" />
                                <span class="d-block text-uppercase mt-2">Elite nero</span>
                            </div>
                            <div v-if="choosenVeicle.color_elite_grey != null && choosenVeicle.color_elite_grey.length > 0" class="col text-center">
                                <img class="img-fluid item-semi-rounded color-image" style="height: 150px;" :src="baseUrl + '/color_elite/elite_grigio.jpg'" />
                                <span class="d-block text-uppercase mt-2">Elite grigio</span>
                            </div>
                            <div v-if="choosenVeicle.color_elite_blue != null && choosenVeicle.color_elite_blue.length > 0" class="col text-center">
                                <img class="img-fluid item-semi-rounded color-image" style="height: 150px;" :src="baseUrl + '/color_elite/elite_blu.jpg'" />
                                <span class="d-block text-uppercase mt-2">Elite blue</span>
                            </div>
                            <div v-if="choosenVeicle.color_elite_red != null && choosenVeicle.color_elite_red.length > 0" class="col text-center">
                                <img class="img-fluid item-semi-rounded color-image" style="height: 150px;" :src="baseUrl + '/color_elite/elite_rosso.jpg'" />
                                <span class="d-block text-uppercase mt-2">Elite rosso</span>
                            </div>
                        </div>
                        <div class="row mt-5" v-if="extras != null && Object.keys(extras).length > 0">
                            <template v-for="(extra, index) in extras">
                                <div class="col text-center" v-for="(file, i) in extra" :key="index + '-' + i">
                                    <img class="img-fluid item-semi-rounded color-image" :src="baseUrl + '/' + index + '/' + file.filename" />
                                    <span class="d-block text-uppercase mt-2">{{ file.name }}</span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-table striped hover :items="veicles" :fields="fields">
                            <template v-slot:cell(data)="data">
                                <span v-for="(val, key) in data.item.data" :key="key">
                                    <span class="d-block" v-if="key != 'descrizione'"> <span v-text="capitalizeFirstLetter(key.toString().replace(/_/g, ' '))"></span>: <span v-text="val"></span> </span>
                                </span>
                            </template>
                            <template v-slot:cell(description)="data">
                                {{ data.item.data.descrizione }}
                            </template>
                            <template v-slot:cell(colors)="data">
                                <span class="d-block" v-if="data.item.color_scar_black">Scar nero: {{ data.item.color_scar_black }}</span>
                                <span class="d-block" v-if="data.item.color_scar_grey">Scar grigio: {{ data.item.color_scar_grey }}</span>
                                <span class="d-block" v-if="data.item.color_scar_blue">Scar blue: {{ data.item.color_scar_blue }}</span>
                                <span class="d-block" v-if="data.item.color_elite_black">Elite nero: {{ data.item.color_elite_black }}</span>
                                <span class="d-block" v-if="data.item.color_elite_grey">Elite grigio: {{ data.item.color_elite_grey }}</span>
                                <span class="d-block" v-if="data.item.color_elite_blue">Elite blu: {{ data.item.color_elite_blue }}</span>
                                <span class="d-block" v-if="data.item.color_elite_red">Elite rosso: {{ data.item.color_elite_red }}</span>
                                <span class="d-block" v-if="data.item.color_original">Originale: {{ data.item.color_original }}</span>
                                <span class="d-block" v-if="data.item.lato_guida">Lato guida grigio: {{ data.item.lato_guida }}</span>
                                <span class="d-block" v-if="data.item.lato_guida_blu">Lato guida blu: {{ data.item.lato_guida_blu }}</span>
                                <span class="d-block" v-if="data.item.lato_guida_nero">Lato guida nero: {{ data.item.lato_guida_nero }}</span>
                                <span class="d-block" v-if="data.item.anteriore_nero">Anteriore nero: {{ data.item.anteriore_nero }}</span>
                                <span class="d-block" v-if="data.item.anteriore_grigio">Anteriore grigio: {{ data.item.anteriore_grigio }}</span>
                                <span class="d-block" v-if="data.item.elite_ecopelle_nero">Elite ecopelle nero: {{ data.item.elite_ecopelle_nero }}</span>
                                <span class="d-block" v-if="data.item.elite_ecopelle_grigio">Elite ecopelle grigio: {{ data.item.elite_ecopelle_grigio }}</span>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Veicle",
        props: ["selected"],
        data: function() {
            return {
                baseUrl: null,
                choosenVeicle: null,
                veicles: [],
                extras: [],
                veicle: null,
                fields: [
                    {
                        key: "description",
                        label: "Descrizione",
                        sortable: true,
                    },
                    {
                        key: "seats",
                        label: "Posti",
                        sortable: true,
                    },
                    {
                        key: "colors",
                        label: "Colori",
                    },
                ],
            };
        },
        mounted() {
            this.$axios
                .post("/ajax.php", {
                    action: "getVeicles",
                    type: this.selected.type,
                    brand: this.selected.brand,
                    model: this.selected.model,
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.extras = response.data.extras;
                        this.veicles = response.data.veicles;

                        this.veicles.forEach((element, index) => {
                            try {
                                this.veicles[index].data = JSON.parse(element.data);
                            } catch (e) {
                                //catch
                            }
                        });
                        this.choosenVeicle = this.veicles[0];

                        this.baseUrl =
                            "/assets/veicoli/" +
                            this.choosenVeicle.brand +
                            "/" +
                            this.choosenVeicle.type +
                            "/" +
                            this.choosenVeicle.model
                                .replace(/ /g, "_")
                                .replace(/\W/g, "")
                                .toLowerCase();
                    }
                });
        },
    };
</script>

<style lang="scss">
    .backButton {
        cursor: pointer;
    }

    .card {
        .box-head {
            font-size: larger;
            border-top: 1px #eee solid;
            border-bottom: 1px #eee solid;

            div.middle {
                border-left: 1px #eee solid;
                border-right: 1px #eee solid;
            }
            span.key {
                color: #c3c3c3;
            }
        }

        .color-image {
            max-height: 190px;
        }
    }
</style>

<template>
    <div class="wrapper">
        <div class="login-container">
            <img class="logo" alt="Scar logo" src="/assets/logo.svg">
            <div class="login-item login-item m-0 mt-5 p-0">
                <div role="group">
                    <b-form-input
                        v-model="password"
                        class="item-rounded"
                        :state="state"
                        type="password"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Inserisci la password"
                    ></b-form-input>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-live-feedback" v-text="error"></b-form-invalid-feedback>
                </div>
            </div>
            <div class="text-center mt-3">
                <button @click="doLogin" id="loginBtn" class="text-uppercase item-rounded btn btn-scar btn-block">Accedi &raquo;</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Login',
    data: function() {
        return {
            state: null,
            error: null,
            password: ''
        }
    },
    methods: {
        doLogin() {
            //Simulate login with axios
            this.$axios.post('/login.php', { pwd: this.password })
            .then(response => {
                if(response.data.code == 200 && response.data.success == true) {
                    this.state = true;
                    this.$emit('login');
                } else {
                    this.state = false;
                    this.error = 'La password non risulta valida.';
                }
            });
        }
    }
}
</script>

<style scoped>
    div.wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        height: 100%;
    }

    div.login-container {
        margin-top: calc(50vh - 100px);
        width:450px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding-left:15px;
        padding-right:15px;
        flex-direction: column;
    }

    .login-item {
        color: #333;
        padding:25px 25px 0;
        margin: 20px 20px 0;	
    }
    
</style>
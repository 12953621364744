import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import Axios from 'axios'
import CrispChat from '@dansmaculotte/vue-crisp-chat'

Vue.prototype.$axios = Axios;

Vue.config.productionTip = false
window.onbeforeunload = function() { return "Usa il tasto indietro del configuratore, non quello del browser."; };

Vue.use(CrispChat, {
  websiteId: '72ff0c2c-3d16-4b55-8712-ca089df48a21',
  disabled: true,
  hideOnLoad: true
})

new Vue({
  render: h => h(App),
}).$mount('#app')
